import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { default as logo } from './../../assets/images/feature-tile-icon-01.svg';
import { default as logo2 } from './../../assets/images/feature-tile-icon-02.svg';
import { default as logo3 } from './../../assets/images/feature-tile-icon-03.svg';
import { default as logo4 } from './../../assets/images/feature-tile-icon-04.svg';
import { default as logo5 } from './../../assets/images/feature-tile-icon-05.svg';
import { default as logo6 } from './../../assets/images/feature-tile-icon-06.svg';
const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Chellos: Your Reward Passport',
    paragraph: 'Welcome to Chellos, your gateway to earning rewards while having fun and creating timeless memories. Our unique reward points, known as Chellos, offer you the flexibility to enjoy your benefits in a way that suits you best. Check out how it works below.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={logo}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Step 1: Create and Participate in Trends
                    </h4>
                  <p className="m-0 text-sm">
                    Create Timeless Memories: Upload your content to an ongoing Trend or support the creation of one.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={logo2}
                      alt="Features tile icon 02"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Step 2: Unlocking Chellos
                    </h4>
                  <p className="m-0 text-sm">
                    The more Trends you participate in, the more likely you are to have earned Chellos! The potential to earn Chellos for your contributions comes from participation, which may include viewing, liking, or sharing content within the Trends.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={logo3}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Step 3: Shop with Your Chellos
                    </h4>
                  <p className="m-0 text-sm">
                    Use your Chellos for shopping or save them. Check in weekly to see if your balance has grown.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={logo4}
                      alt="Features tile icon 04"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Balance Appreciation: Watch Your Chellos Grow!
                    </h4>
                  <p className="m-0 text-sm">
                    We evaluate our company's health and allocate a substantial portion of our profits to boost Chello holders' balances. Watch your Chellos grow!
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={logo5}
                      alt="Features tile icon 05"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Chello Safety: Guaranteed Value
                    </h4>
                  <p className="m-0 text-sm">
                    A Chello's value will remain the same. Once you've earned a Chello, you have a guaranteed spending credit to use within the platform.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={logo6}
                      alt="Features tile icon 06"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Beyond Chellos, Trends, and the Marketplace
                    </h4>
                  <p className="m-0 text-sm">
                    In addition to Chellos, Trends & shopping, our platform offers an array of features designed to enhance content presentation and nurture the value of your content. Discover them on the app!
                    </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;