import React, { useEffect, useRef }  from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import axios from 'axios';
import Button from "../elements/Button";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

// Reuse logic in other components


const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  let [counter, setCounter] = React.useState(0);
  let [moreStuff, setMoreStuff] = React.useState("");
  const myContainer = useRef(null);
  useEffect(() => {
    console.log("myContainer..", myContainer.current);
  });

  function updateInputValue(evt) {
    moreStuff = evt.target.value
  }




  function button_clicked() {
    return function () {
      // you code

      axios.post('../app/early_user_registration/register_early_user2.php', {
        email: moreStuff
      })
          .then(function (response) {
            //  console.log(response);

            if (response.data.success === 1)
            {
              //console.log("Return Data --->")
              // console.log(response.data.returnData);

             // counter = 1
              setCounter(counter = 1)

            }
            else
            {
              //counter = 2
              setCounter(counter = 2)
            }

          })
          .catch(function (error) {
            setCounter(counter = 3)
            //counter = 3
          });


    }
    /*
() => setCounter(counter + 1)
    * */
    //setCounter(counter + 1)
    //return counter.toFixed(2);
  }


  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan" id="EarlySignUpArea">
            {counter === 0 &&
            <h3 className="m-0">
              Pre-register for early access to the app.
            </h3>
            }
            {counter === 1 &&
            <h3 className="m-0">
              Success
            </h3>
            }
            {counter === 2 &&
            <h3 className="m-0">
              Try a different email
            </h3>
            }
            {counter === 3 &&
            <h3 className="m-0">
              Connection Problem
            </h3>
            }
            <p> You will be guided to an external link to submit your email.</p>
          </div>
          <div className="cta-action">
            <Button tag="a" color="light" wideMobile href="https://form.jotform.com/232888455821063">
              Sign up early
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;